import React from 'react';
import styled from 'styled-components';
import {
  Wrapper,
  Header,
  Button,
  Menu,
  FormWrapper,
  Content,
  Spacer,
  COLORS,
  FlexBox
} from 'components';
import { getOrganization, updateOrganization } from 'data-graphql'
import { isMedia, isMTI } from 'helpers';
import {
  ButtonGroup,
  CurrentShowsTable,
  FutureShowsTable,
  PastShowsTable,
  AboutOrgTable,
  EmailsOrgTable,
  ContactsOrgTable,
  ResetOrgTable,
  EditOrgForm,
  TrackManager,
  MergeForm
} from './components';
import Spinner from 'react-spinkit';
import { toast } from 'react-toastify'

const StyledDiv = styled.div`
  backgroundcolor: #f1efef;
  z-index: 10;
`;

export default class Organizations extends React.Component {
  constructor() {
    super();
    this.state = {
      editOrganizationModalOpen: false,
      menu: 'All Shows',
      organization: {},
      bookings: [],
      refreshBookings: false,
      dataLoaded: false,
      editOrg: false,
      menuOpen: false,
      view: false
    };
  }

  componentDidMount() {
    this.getComponentOrganization();
  }

  getComponentOrganization = async () => {
    const VARS = { id: this.props.match.params.id };
    const REQ_OBJECT = `{
      id
      name
      account_number
      website
      status
      group
      pricing
      address
      city
      state
      zip
      address_type
      created_at
      client_name
      show_count
      notes
      require_payment_track_edit
      username
      ${
        isMTI
          ? `executive {
            id
            name
            email
          }`
          : ''
      }
      emails {
        id
        template
        address
        subject
        body
        date_sent
        times_sent
        booking {
          rehearsal_code
        }
      }
      active_contacts: contacts(active: true) {
        id
        full_name
        first_name
        last_name
        email
        position
        phone_number
        phone_number_two
        is_account_admin
        active
      }
      inactive_contacts: contacts(active: false) {
        id
        full_name
        first_name
        last_name
        email
        position
        phone_number
        phone_number_two
        is_account_admin
        active
      }
      bookings {
        id
        created_at
        show {
          name
          id
        }
        custom_setlist {
          id
        }
        rehearsal_code
        rehearsal_edit_code
        opening
        expiration
        rehearsal_active_date
        production_active_date
        ${isMTI ? '' : 'rate'}
        is_production_access
        ${isMTI ? '' : 'is_contract_ready'}
        ${isMTI ? '' : 'is_license_ready'}
        ${isMTI ? '' : 'is_payment_ready'}
        ${isMTI ? '' : 'quote_avg_price'}
        ${isMTI ? '' : 'quote_avg_attend'}
        ${isMTI ? '' : 'quote_performances'}
        ${isMTI ? '' : 'educational'}
        ${isMTI ? '' : 'edits_status'}
        ${isMTI ? '' : 'rate_status'}
        ${isMTI ? 'mti_terms_accepted' : ''}
        ${isMTI ? 'foreign_booking_id' : ''}
        notes
        notes_color
        state
        is_downloaded
        cancelled_at
        booked_at
        authorized_device_max
        rehearsal_editing_device_max
        authorizations {
          is_edit_rehearsal
          device {
            id
            type
            description
            users {
              username
              id
              first_name
              last_name
            }
          }
          user {
            id
            username
            first_name
            last_name
          }
        }
      }
    }`;

    const RESULT = await getOrganization(VARS, REQ_OBJECT);
    const emails = this.parseEmails(RESULT.data.getOrganization.emails);
    this.setState({
      organization: RESULT.data.getOrganization,
      dataLoaded: true,
      emails: emails
    });
  };

  parseEmails = emails => {
    const newEmails = [];
    const uniqueEmails = [...new Set(emails.map(data => data.subject))];
    uniqueEmails.map(subject => {
      const newArr = emails.filter(email => email.subject === subject);
      const newObj = {
        id: newArr[0].id,
        date_sent: newArr[0].date_sent,
        template: newArr[0].template,
        address: newArr[0].address,
        subject: newArr[0].subject,
        body: newArr[0].body,
        times_sent: newArr.length,
        booking: newArr[0].booking,
        emails: newArr
      };
      newEmails.push(newObj);
      return subject;
    });
    return newEmails;
  };

  handleChildChange = async () => {
    await this.getComponentOrganization();
    this.setState({ editOrg: false });
  };

  nav = () => {
    this.setState({ menu: 'All Shows' });
  };

  showBookingTracks = booking_id => {
    const booking = this.state.organization.bookings.filter(
      booking => booking.id === booking_id
    )[0];
    this.setState({ menu: 'tracks', tracksBooking: booking });
  };

  openMenu = () => {
    this.setState({
      menuOpen: this.state.menuOpen === 'create' ? false : 'create'
    });
  };

  editOrg = () => {
    this.setState({
      editOrg: this.state.editOrg === 'create' ? false : 'create'
    });
  };

  menuSelect = e => {
    this.setState({
      menu: e.target.value,
      menuOpen: this.state.menuOpen === 'create' ? false : 'create'
    });
  };

  handleClick = e => {
    this.setState({
      view: this.state.view === e.target.value ? false : e.target.value
    });
  };

  handleArchive = async () => {
    if (!confirm('Are you sure that you want to archive this organization?')) return

    const payload = {archived: true}
    const VARS = { id: this.props.match.params.id, input: payload };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;
    const RESULT = await updateOrganization(VARS, REQ_OBJECT);

    if (RESULT.data) {
      toast.success('The organization has been archived.');
      window.location.href = '/admin/organizations'
    } else {
      toast.error('There was a problem archiving the organization.');
    }

  }

  render() {
    const { editOrg, menuOpen } = this.state;
    const hide = { display: this.state.menu === 'tracks' ? 'inherit' : 'none' };
    // if (!this.state.organization.id) {
    //   return (
    //     <div>
    //       <Header>
    //         <Header.Head bold huge style={{ textAlign: 'left' }}>
    //           {this.state.organization.name}

    //           <Spacer size="8px" />
    //         </Header.Head>
    //       </Header>
    //       <Wrapper>
    //         <FlexBox justify="space-evenly">
    //           <Spinner name="three-bounce" color={COLORS.darkRed} />
    //         </FlexBox>
    //       </Wrapper>
    //     </div>
    //   );
    // } else {
    return (
      <div>
        <Header>
          <Header.Head bold huge style={{ textAlign: 'left' }}>
            {this.state.organization.name}
            {!isMTI && (
              <>
                <Button onClick={this.editOrg} header>
                  Edit Organization {editOrg === 'create' ? '-' : '+'}
                </Button>
                <Button value="merge" header onClick={this.handleClick}>
                  Merge {this.state.view === 'merge' ? '-' : '+'}
                </Button>
                <Button header onClick={this.handleArchive}>
                  Archive Organization
                </Button>
              </>
            )}
            <Spacer size="8px" />
          </Header.Head>
          {isMedia() === 'phone' ? (
            <>
              <Button darkRed menu onClick={this.openMenu}>
                {this.state.menu} {menuOpen === 'create' ? '-' : '+'}
              </Button>
              <Content
                pose={menuOpen === 'create' ? 'open' : 'closed'}
                style={{
                  overflow: menuOpen === 'create' ? 'visible' : 'hidden'
                }}
              >
                <Menu>
                  <button
                    value="All Shows"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'All Shows'}
                  >
                    All Shows
                  </button>
                  <button
                    value="Current Shows"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Current Shows'}
                  >
                    Current Shows
                  </button>
                  {!isMTI && (
                    <button
                      value="Future Shows"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Future Shows'}
                    >
                      Future Shows
                    </button>
                  )}
                  <button
                    value="Past Shows"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Past Shows'}
                  >
                    Past Shows
                  </button>
                  <button
                    value="About"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'About'}
                  >
                    About
                  </button>
                  <button
                    value="Emails"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Emails'}
                  >
                    Emails
                  </button>
                  <button
                    value="Contacts"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Contacts'}
                  >
                    Contacts
                  </button>
                  <button
                    value="Reset"
                    onClick={this.menuSelect}
                    active={this.state.menu === 'Reset'}
                  >
                    Reset
                  </button>
                  <button style={hide} active={this.state.menu === 'tracks'}>
                    Tracks
                  </button>
                </Menu>
              </Content>
            </>
          ) : (
            <Menu>
              <button
                value="All Shows"
                onClick={this.menuSelect}
                active={this.state.menu === 'All Shows'}
              >
                All Shows
              </button>
              <button
                value="Current Shows"
                onClick={this.menuSelect}
                active={this.state.menu === 'Current Shows'}
              >
                Current Shows
              </button>
              {!isMTI && (
                <button
                  value="Future Shows"
                  onClick={this.menuSelect}
                  active={this.state.menu === 'Future Shows'}
                >
                  Future Shows
                </button>
              )}
              <button
                value="Past Shows"
                onClick={this.menuSelect}
                active={this.state.menu === 'Past Shows'}
              >
                Past Shows
              </button>
              <button
                value="About"
                onClick={this.menuSelect}
                active={this.state.menu === 'About'}
              >
                About
              </button>
              <button
                value="Emails"
                onClick={this.menuSelect}
                active={this.state.menu === 'Emails'}
              >
                Emails
              </button>
              <button
                value="Contacts"
                onClick={this.menuSelect}
                active={this.state.menu === 'Contacts'}
              >
                Contacts
              </button>
              <button
                value="Reset"
                onClick={this.menuSelect}
                active={this.state.menu === 'Reset'}
              >
                Reset
              </button>
              <button style={hide} active={this.state.menu === 'tracks'}>
                Tracks
              </button>
            </Menu>
          )}
        </Header>
        {!this.state.dataLoaded && !this.state.organization.bookings && (
          <Wrapper>
            <FlexBox justify="space-evenly">
              <Spinner
                name="three-bounce"
                color={COLORS.darkRed}
                fadeIn="none"
              />
            </FlexBox>
          </Wrapper>
        )}
        {this.state.dataLoaded && this.state.organization.bookings && (
          <Wrapper>
            <Content
              pose={this.state.editOrg === 'create' ? 'open' : 'closed'}
              style={{
                overflow: this.state.editOrg === 'create' ? 'visible' : 'hidden'
              }}
            >
              <FormWrapper>
                <EditOrgForm
                  organization={this.state.organization}
                  onChange={this.handleChildChange}
                />
              </FormWrapper>
            </Content>
            <Content
              pose={this.state.view === 'merge' ? 'open' : 'closed'}
              style={{
                overflow: this.state.view === 'merge' ? 'visible' : 'hidden'
              }}
            >
              <FormWrapper>
                <MergeForm
                  organization={this.state.organization}
                  onButtonChange={this.handleChildChange}
                />
              </FormWrapper>
            </Content>
            {this.state.menu === 'tracks' && (
              <TrackManager
                booking={this.state.tracksBooking}
                onChange={this.handleChildChange}
              />
            )}
            {this.state.menu === 'All Shows' && (
              <ButtonGroup
                organization={this.state.organization}
                onButtonChange={this.handleChildChange}
              />
            )}
            <StyledDiv>
              {(this.state.menu === 'All Shows' ||
                this.state.menu === 'Current Shows') && (
                <CurrentShowsTable
                  organization={this.state.organization}
                  onChange={this.handleChildChange}
                  showTracks={this.showBookingTracks}
                />
              )}
              {(this.state.menu === 'All Shows' ||
                this.state.menu === 'Future Shows') &&
                !isMTI && (
                  <FutureShowsTable
                    organization={this.state.organization}
                    onChange={this.handleChildChange}
                    showTracks={this.showBookingTracks}
                  />
                )}
              {(this.state.menu === 'All Shows' ||
                this.state.menu === 'Past Shows') && (
                <PastShowsTable
                  organization={this.state.organization}
                  onChange={this.handleChildChange}
                />
              )}
              {this.state.menu === 'About' && (
                <AboutOrgTable organization={this.state.organization} />
              )}
              {this.state.menu === 'Emails' && (
                <EmailsOrgTable
                  onChange={this.handleChildChange}
                  organization={this.state.organization}
                  emails={this.state.emails}
                />
              )}
              {this.state.menu === 'Contacts' && (
                <ContactsOrgTable
                  onChange={this.handleChildChange}
                  organization={this.state.organization}
                />
              )}
              {this.state.menu === 'Reset' && (
                <ResetOrgTable
                  organization={this.state.organization}
                  onChange={(this.handleChildChange, this.nav)}
                />
              )}
            </StyledDiv>
          </Wrapper>
        )}
      </div>
    );
    // }
  }
}
