import React from 'react';
import { http } from 'data-graphql';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Modal, Header, FormWrapper, Text, Input, Button, Icon } from 'components';

const Wrapper = styled.div`
  .table {
    display: block;
    max-width: 1300px;
    margin: .5em auto;
    padding: 15px 20px;
  }

  .table table {
    width: 100%;
    background: white;
    table-spacing: 0;
    border-collapse: collapse;
  }

  .table thead tr {
    background: #6e1406;
    color: white;
  }

  .topInfo {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 1em;
    max-width: 1300px;
    border-radius: 8px;
    border: 1px solid #222;
    padding: .5em;
    text-align: left;
  }
  
  .topInfo span {
    color: #6e1406;
  }

  .topInfoHeader {
    font-weight: bold;
    text-transform: uppercase;
  }

  .topInfo > div {
    width: 50%;
    padding: 1em;
  }

  .topInfo > div > div:first-child {
    margin-bottom: .5em;
    font-size: 1.2em;
    font-weight: bold;
  }

  .topInfo .field {
    margin-bottom: .5em;
  }

  tr td {
    margin: 0;
    border: none;
    text-align: center;
  }

  .table tbody td {
    border-bottom: 1px solid #CCC;
    color: #6e1406;
  }

  .table th {
    padding: .4em;
  }

  .table tbody td {
    padding: .4em;
  }

  .table td:first-child {
    width: 80px;
    text-align: center;
  }

  .table td:nth-child(2),
  .table th:nth-child(2) {
    width: 250px;
    padding-left: 1em;
    text-align: left;
  }

  .table input {
    border: 1px solid #AAA;
  }

  input[disabled] {
    background-color: #FFE;
  }

  .radios > div {
    margin-bottom: .5em;
  }

  .radios input {
    margin-right: .5em; 
  }

  .updateButton {
    margin-bottom: 2em;
  }

  .editorCommentItem {
    margin: 6px 0;
    text-align: left;
  }

  .editRequestItem {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 6px 0;
    text-align: left;
  }

  .editRequestItem span {
    margin-left: 12px;
  }

  .paymentNickname {
    font-size: 1.2em;
    font-weight: bold;
    margin: .5em 0;
    background: #EFEFEF;
    display: block;
    padding: 6px;
    border-radius: 10px;
    max-width: 450px;
    margin-bottom: 2em;
  }

  .editRequestBox, .paymentBox {
    border-radius: 8px;
    border: 1px solid #222;
    padding: .5em;
    text-align: left;
    margin-bottom: 16px;
    max-width: 1300px;
    margin: 16px auto;
  }

  .paymentBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 24px 0 ;
  }

  .paymentBox > div {
    width: 45%;
  }

  .hoursForm {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .hoursForm .hoursInput {
    width: 80px;
    padding: 0 10px;
    margin-right: 30px;
  }

  .hoursForm input {
    width: 100%;
    height: 30px;
  }

  .hoursForm input {
    background: #EFEFEF;
  }

  .chargeTitle {
    margin: 2em auto 1em;
  }

  table th.editRequestHeader {
    text-align: left;
    padding-left: 70px;
  }
  
  table th.editorCommentHeader {
    text-align: left;
  }

  .editRequestBox .editsTitle {
    font-weight: bold;
    text-transform: uppercase;
    color: #6e1406;
    display: block;
    text-align: center;
    margin-top: .5em;
  }

  .editorNotesBox {
    padding: 1em;
  }

  .editorNotesBox span {
    color: #6e1406;
    font-weight: bold;
    font-size: 1.2em;
  }

  .editorNotesBox textarea {
    width: 100%;
    min-height: 100px;
    resize: vertical;
    border: 1px solid #222;
    border-radius: 8px;
    padding: 6px;
    display: block;
    margin: 8px 0;
  }

  .chargeModal {
    text-align: center;
    font-size: 1.1em;
    font-weight: bold;
    color: black;
  }

  .chargeModalButtons {
    margin-top: 2em;
    display: flex;
    justify-content: center;
  }

  .chargeModalButtons button {
    margin: 0 1em;
  }

  .noTokenDisclaimer div:nth-child(1) {
    font-weight: bold;   
    font-size: 1.2em;
    margin-bottom: .5em;
  }

  .noTokenDisclaimer div:nth-child(1) {
    
  }
`

const rates = {
  '5': 60,
  '2': 120,
}

export default class OrgEdits extends React.Component {
  constructor(props) {
    console.log('PROPS ', props)
    super();
    this.state = {
      showChargeModal: false,
      show: {},
      edit: {},
      booking: {},
      update: {},
      hours: 1,
      editingEntry: null,
      editNewEntry: '',
      editorComment: '',
      editorNotes: '',
    };
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      update: {
        ...this.state.update,
        [e.target.name]: value
      }
    });
  };

  saveEditing = async (index, edit) => {
    const edits = this.state.edit.edits

    let i = undefined;
    edits.map((e, _i) => {
      if (e.id === edit.id) i = _i;
    })

    if (typeof i === 'undefined') return;

    edits[i].edit[index] = this.state.editNewEntry;

    if (!edits[i].comments) {
      edits[i].comments = [].fill('', 0, edits[i].edit.length);
    }
    edits[i].comments[index] = this.state.editorComment;
    this.setState({ edit: { edits } }, this.updateTop)
    this.setEditing();
  }

  triggerCheckbox = async (index, edit) => {
    const edits = this.state.edit.edits
    let i = undefined;
    edits.map((e, _i) => {
      if (e.id === edit.id) i = _i;
    })
    if (typeof i === 'undefined') return;
    if (!edits[i].checkbox) {
      edits[i].checkbox = [].fill(false, 0, edits[i].edit.length);
    }
    edits[i].checkbox[index] = !edits[i].checkbox[index];
    console.log(edits)
    this.setState({ edit: { ...this.state.edit, edits } }, this.updateTop)
  }

  setEditing = (index, edit) => {
    if (!edit || typeof index === 'undefined') return this.setState({ editingEntry: null });
    this.setState({
      editingEntry: `${edit.id}-${index}`,
      editNewEntry: edit.edit[index],
      editorComment: edit.comments ? edit.comments[index] : ''
    });
  }

  updateClient = async () => {
    if (this.state.loading) return
    if (!confirm('Are you sure you want to send an email to the client?')) {
      return
    }
    this.setState({ loading: true });
    await http().post(`/editing/notify`, { id: this.state.edit.id })
      .then(response => {
        if (response.success) return toast.success('Successfully sent email');
        toast.error('Error sending email, ' + (response.message || 'Something went wrong'));
      })
      .catch(error => {
        toast.error('Error sending email, ' + (error.message || 'Something went wrong'));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  updateTop = async () => {
    this.setState({ loading: true });
    console.log(this.state)
    await http().post(`/editing/update`, {
      id: this.props.match.params.id,
      status: this.state.update.status,
      assigned_to: this.state.update.assigned_to,
      edits: this.state.edit.edits,
      editor_notes: this.state.editorNotes,
    })
      .then(response => {
        if (response.success) return toast.success('Successfully updated edit');
        toast.error('Error updating, ' + (response.message || 'Something went wrong'));
      })
      .catch(error => {
        toast.error('Error updating, ' + (error.message || 'Something went wrong'));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  triggerCharge = () => {
    const checkboxes = document.querySelectorAll('#editRows [type="checkbox"]');
    for (let i = 0; i < checkboxes.length; i++) {
      if (!checkboxes[i].checked) {
        return toast.error('You must check all edits before charging');
      }
    }

    this.setState({ showChargeModal: true })
  }

  performCharge = async () => {
    this.setState({ showChargeModal: false });
    toast.success(`STAGING - simulating charging for ${this.state.hours} hours`);
  }

  async componentDidMount() {
    await http().get(`/editing/${this.props.match.params.id}`).then(response => {
      console.log(response)
      if (!response.success) {
        return toast.error('Failed to retrieve edit');
      }
      console.log(response)
      this.setState({ ...response, update: response.edit, editorNotes: response.edit.editor_notes });
    }).catch(error => {
      console.log(error)
      toast.error(error.message);
    })
  }


  render() {
    if (!this.state.edit || !this.state.edit.edits || !this.state.booking) return null;

    const subtotal = this.state.hours && !isNaN(this.state.hours) ? (this.state.hours * rates[this.state.edit.turnaround_time || '2']) : 0
    const processingFee = Math.round((subtotal / .99 - subtotal) * 100) / 100;
    const total = subtotal + processingFee;

    return <Wrapper>
      <Modal
        width="350px"
        open={this.state.showChargeModal}
        onClose={() => this.setState({ showChargeModal: false })}
        closeOnOverlay={true}
      >
        <div className="chargeModal">
          <div>Organization: {this.state.org.name}</div>
          <div>Show: {this.state.show.name}</div>
          <div>Total hours: {this.state.hours}</div>
          <div>Subtotal: ${subtotal.toFixed(2)}</div>
          <div>Processing Fee: ${processingFee.toFixed(2)}</div>
          <div>Total charge: ${total.toFixed(2)}</div>
          <div className="chargeModalButtons">
            <Button onClick={this.performCharge}>
              Charge ${total.toFixed(2)}
            </Button>
            <Button onClick={() => this.setState({ showChargeModal: false })}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Header>
        <Header.Head bold huge>
          Edit request #{this.state.editN} for {this.state.show.name}
        </Header.Head>
      </Header>
      <FormWrapper>
        <div className='topInfo'>
          <div className='topInfo1'>
            <div className="topInfoHeader">Contact information</div>
            <div>
              <div className="field">
                <span>Name:</span>
                <Input value={this.state.edit.name} />
              </div>
              <div className="field">
                <span>Phone:</span>
                <Input value={this.state.edit.phone} />
              </div>
              <div className="field">
                <span>Email:</span>
                <Input type="email" value={this.state.edit.email} />
              </div>
            </div>
          </div>
          <div className='topInfo2'>
            <div className="topInfoHeader">Organization Information</div>
            <div>
              <div className="field">
                <span>Organization:</span>
                <Input value={this.state.org.name} disabled />
              </div>
              <div className="field">
                <span>Opening Date:</span>
                <Input value={this.state.booking.opening} disabled />
              </div>
              <div className="field">
                <span>Account Number:</span>
                <Input value={this.state.org.account_number} disabled />
              </div>
            </div>
          </div>
          <div className='topInfo3'>
            <div><span>Turnaround Time:</span></div>
            {this.state.edit.turnaround_time === '5' && (
              <div> 5 Business-day ($60/hour, $30 half hour minimum) </div>
            )}

            {this.state.edit.turnaround_time === '2' && (
              <div> 2 Business-day ($120/hour) </div>
            )}
          </div>
          <div className='topInfo4'>
            <div><span>Status:</span></div>
            <div>
              <select onChange={this.handleChange} name="status" value={this.state.update.status}>
                <option checked={this.state.update.status === 'awaiting_processing'} value="awaiting_processing">Awaiting Processing</option>
                <option checked={this.state.update.status === 'received'} value="received">Received</option>
                <option checked={this.state.update.status === 'on_hold'} value="on_hold">On Hold</option>
                <option checked={this.state.update.status === 'complete'} value="complete">Complete</option>
                <option checked={this.state.update.status === 'cancelled'} value="cancelled">Cancelled</option>
              </select>
            </div>
          </div>
          <div className='topInfo4'>
            <div><span>Editor:</span></div>
            <div>
              <Input name="assigned_to" onChange={this.handleChange} value={this.state.update.assigned_to} />
            </div>
          </div>
          <div className='topInfo4'>
            <div className="updateButton" disabled={this.state.loading}>
              <Button onClick={this.updateTop}>Update status and assignee</Button>
            </div>

            <div className="updateButton" disabled={this.state.loading}>
              <Button onClick={this.updateClient}>Send status update email</Button>
            </div>

          </div>
        </div>

        <div className="editRequestBox">
          <Text large className="editsTitle">Edit Requests</Text>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Cue #</th>
                  <th>Track Name</th>
                  <th className="editRequestHeader">Edit Request</th>
                  <th className="editorCommentHeader">Editor Comments</th>
                </tr>
              </thead>
              <tbody id="editRows">
                {this.state.edit.edits.map(edit => {
                  return <tr key={edit.id}>
                    <td>{edit.cue_number}</td>
                    <td>{edit.track_name}</td>
                    <td>
                      {edit.edit.map((e, i) => {
                        const editing = this.state.editingEntry === `${edit.id}-${i}`;

                        return (
                          <div key={i} className="editRequestItem">
                            {editing ? (
                              <Button onClick={() => this.saveEditing(i, edit)}>Save</Button>
                            ) : (
                                <Icon name="pencil-1" onClick={() => this.setEditing(i, edit)} />
                              )}
                            <input
                              type="checkbox"
                              checked={edit.checkbox && edit.checkbox[i]}
                              onChange={() => this.triggerCheckbox(i, edit)}
                            />
                            {editing ?
                              <Input
                                value={this.state.editNewEntry}
                                onChange={e => this.setState({
                                  editNewEntry: e.target.value
                                })} />
                              : <span>{e}</span>
                            }
                          </div>
                        )
                      })}
                    </td>
                    <td>
                      {edit.edit.map((e, i) => {
                        const editing = this.state.editingEntry === `${edit.id}-${i}`;

                        return (
                          <div key={i} className="editorCommentItem">
                            {editing ? (
                              <Input
                                value={this.state.editorComment}
                                onChange={e => this.setState({
                                  editorComment: e.target.value
                                })} />
                            ) : (
                                <div>{edit.comments && edit.comments[i] || ''}</div>
                              )}
                          </div>
                        )
                      })}
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="paymentBox">
          <div className="editorNotesBox">
            <div><span>Editor Notes</span></div>
            <div>
              <textarea
                value={this.state.editorNotes}
                onChange={e => this.setState({
                  editorNotes: e.target.value
                })}
                placeholder="Anything written here will be visible to the client"></textarea>
            </div>
            <div>
              <Button onClick={this.updateTop}>
                Save Changes
              </Button>
            </div>
          </div>
          {this.state.edit.card_token ? (

            <div>
              <Text large>Payment method</Text>
              <div className="paymentNickname">
                {this.state.edit.card_nickname}
              </div>

              <div className="hoursForm">
                Hours:
              <Input className="hoursInput" type="number"
                  value={this.state.hours}
                  onChange={e => this.setState({ hours: e.target.value >= 0 ? e.target.value : 0 })} />
                <Button onClick={this.triggerCharge}>
                  Charge{' '}
                  {this.state.hours && !isNaN(this.state.hours) ? `$${(this.state.hours * rates[this.state.edit.turnaround_time || '2']).toFixed(2)}` : '$0.00'}
                </Button>
              </div>
            </div>
          ) : (
              <div className="noTokenDisclaimer">
                <div>Note:</div>
                <div>
                  This edit request does not contain a payment
                  method due to the organization having payment collection
                  disabled for edits at the time it was created.
                </div>
              </div>
            )}
        </div>
      </FormWrapper>
    </Wrapper>;
  }
}

