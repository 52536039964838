import React from 'react';
import { Link } from 'react-router-dom';
import { http } from 'data-graphql';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Header, Button } from 'components';

const statusLabels = {
  'awaiting_processing': 'Awaiting Processing',
  'received': 'Received',
  'on_hold': 'On Hold',
  'complete': 'Complete',
  'cancelled': 'Cancelled',
}

const Wrapper = styled.div`
  .table {
    display: block;
    max-width: 1300px;
    margin: .5em auto;
    padding: 15px 20px;
  }

  .table table {
    width: 100%;
    background: white;
    table-spacing: 0;
    border-collapse: collapse;
  }

  .table thead tr {
    background: #6e1406;
    color: white;
  }

  tr td {
    margin: 0;
    border: none;
    text-align: center;
  }

  .table tbody td {
    border-bottom: 1px solid #CCC;
  }

  .table th {
    padding: .4em;
    cursor: pointer;
  }

  .table tbody td {
    padding: .4em;
  }

  .table td:first-child {
    max-width: 50px;
    text-align: center;
  }

  .table td:nth-child(2) {
    max-width: 250px;
    padding-left: 1em;
  }

  .table input {
    border: 1px solid #AAA;
  }

  .overdue.status_pending, .overdue.status_submitted {
    background: #FFCDD2;
  }

  .status_complete, .status_cancelled {
    background #DDD;
  }
`

const printDate = date => {
  const d = new Date(date);
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
}

class SortIndicator extends React.Component {
  render() {
    const { sortBy, sortDirection } = this.props;
    if (sortBy === this.props.field) {
      return <span>{sortDirection === 'asc' ? ' \\/' : ' /\\'}</span>
    }
    return null;
  }
}

export default class OrgEdits extends React.Component {
  constructor() {
    super();
    this.state = {
      edits: [],
      sortBy: null,
      sortDirection: 'asc',
    };
  }

  async componentDidMount() {
    await http().get('/editing/admin_list').then(response => {
      if (!response.success) {
        return toast.error('Failed to retrieve edits');
      }
      console.log(response.items)
      this.setState({ edits: response.items });
    }).catch(error => {
      console.log(error)
      toast.error(error.message);
    })
  }

  sortBy = field => {
    let sortDirection = this.state.sortDirection;
    if (this.state.sortBy === field) {
      sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      sortDirection = 'asc';
    }

    const edits = this.state.edits.sort((_a, _b) => {
      let a = _a;
      let b = _b;

      // if the field is a date, convert it to a date object
      if (field === 'created_at' || field === 'due_at') {
        a[field] = new Date(a[field]).valueOf();
        b[field] = new Date(b[field]).valueOf();
      }


      // Sort considering direction
      if (a[field] < b[field]) return sortDirection === 'asc' ? -1 : 1
      if (a[field] > b[field]) return sortDirection === 'asc' ? 1 : -1
      return 0

    });
    this.setState({ edits, sortBy: field, sortDirection });
  }

  render() {
    return <Wrapper>
      <Header>
        <Header.Head bold huge>
          Track edit requests
        </Header.Head>
      </Header>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th onClick={() => this.sortBy('organization_name')}>
                Organization
                <SortIndicator
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection}
                  field='organization_name' />
              </th>
              <th onClick={() => this.sortBy('show_name')}>
                Show
                <SortIndicator
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection}
                  field='show_name' />
              </th>
              <th onClick={() => this.sortBy('created_at')}>
                Submission date
                <SortIndicator
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection}
                  field='created_at' />
              </th>
              <th onClick={() => this.sortBy('due_at')}>
                Due date
                <SortIndicator
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection}
                  field='due_at' />
              </th>
              <th onClick={() => this.sortBy('turnaround_time')}>
                Turnaround
                <SortIndicator
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection}
                  field='turnaround_time' />
              </th>
              <th onClick={() => this.sortBy('assigned_to')}>
                Editor
                <SortIndicator
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection}
                  field='assigned_to' />
              </th>
              <th onClick={() => this.sortBy('status')}>
                Status
                <SortIndicator
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection}
                  field='status' />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.edits.map(edit => {
              let className = `status_${edit.status}`
              if(new Date(edit.due_at).valueOf() < new Date().valueOf()) {
                className += ' overdue'
              }

              return <tr key={edit.id} className={className}>
                <td>{edit.organization_name}</td>
                <td>{edit.show_name}</td>
                <td>{printDate(edit.created_at)}</td>
                <td>{printDate(edit.due_at)}</td>
                <td>{edit.turnaround_time} days</td>
                <td>{edit.assigned_to}</td>
                <td>{statusLabels[edit.status] || 'Unknown'}</td>
                <td>
                  <Link to={`/admin/edits/${edit.id}`}>
                    <Button>View Details</Button>
                  </Link>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </Wrapper>;
  }
}

