// import React from 'react'
import styled from 'styled-components';
import { default as media } from './screenSize';

export default styled.div`
  padding: 20px;
  background-color: white;
  margin: 20px auto;
  width: 90%;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.21);
  ${media.phone`
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
  `};
`;
